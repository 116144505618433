import { InfoCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useState } from "react";
import { BeaconMapLegend, LegendItem } from "./BeaconMapLegend";

interface MapLegendButtonProps {
  legendStyle?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
}

const mapLegendStyle = {
  position: "absolute" as "absolute",
  right: "10px",
  margin: "10px",
  zIndex: 1000,
};

export function MapLegendButton({
  legendStyle,
  buttonStyle,
}: MapLegendButtonProps) {
  const [isMapInfoVisible, setIsMapInfoVisible] = useState(false);

  const toggleMapInfo = (state: boolean) => {
    setIsMapInfoVisible(state);
  };

  return (
    <>
      <div style={buttonStyle ? buttonStyle : mapLegendStyle}>
        <Button
          aria-label="map-legend-button"
          type="default"
          className="info-button"
          onMouseEnter={() => toggleMapInfo(true)}
          onMouseLeave={() => toggleMapInfo(false)}
          icon={<InfoCircleOutlined />}
        />
        {isMapInfoVisible && (
          <BeaconMapLegend
            legendItems={[LegendItem.Markers, LegendItem.Polygons]}
            style={legendStyle}
          />
        )}
      </div>
    </>
  );
}
