import React, { useCallback, useState } from "react";
import { FullscreenOutlined, FullscreenExitOutlined } from "@ant-design/icons";

interface MaximizableProps {
  children: React.ReactNode;
  buttonPosition?: "top-right" | "bottom-right";
  hide?: boolean;
  label?: React.ReactNode;
  onToggled?(): void;
}

export function Maximizable(props: MaximizableProps) {
  const [isMaximized, setIsMaximized] = useState(false);
  const { onToggled } = props;

  const toggle = useCallback(() => {
    setIsMaximized((m) => !m);
    if (onToggled) {
      onToggled();
    }
  }, [setIsMaximized, onToggled]);

  if (props.hide) {
    return <>{props.children}</>;
  }

  const buttonPosition = props.buttonPosition || "top-right";
  const buttonPositioning: React.CSSProperties =
    buttonPosition === "bottom-right"
      ? { right: "10px", bottom: "10px" }
      : { right: "10px", top: "10px" };

  const minimizedStyle: React.CSSProperties = {
    position: "relative",
    height: "100%",
  };
  const maximizedStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    backgroundColor: "rgb(240, 242, 245)",
    zIndex: 100000000,
  };

  return (
    <div style={isMaximized ? maximizedStyle : minimizedStyle}>
      {props.children}
      <div
        style={{
          ...buttonPositioning,
          position: "absolute",
          zIndex: 1000,
          display: "grid",
          gridTemplateColumns: "auto auto",
          gridColumnGap: "1em",
        }}
      >
        <span style={{ alignSelf: "center" }}>{props.label}</span>
        <div
          className="button-like"
          onClick={toggle}
          style={{
            padding: "0 5px",
            aspectRatio: "1",
            backgroundColor: "white",
            border: "1px solid #ccc",
            cursor: "pointer",
          }}
        >
          {isMaximized ? <FullscreenExitOutlined /> : <FullscreenOutlined />}
        </div>
      </div>
    </div>
  );
}
