import { Beacon, ConstructionProject, ConstructionSite } from "../../../api";
import { Map } from "../../../components/map/Map";
import { useNodeSelectedContext } from "../NodeSelectedContext";
import {
  getPolygonsProjects,
  getPolygonsSites,
  getPolylinesSites,
  transformBeaconsToMarkers,
} from "../../../components/map/mapUtils";
import { useMemo } from "react";
import { MapLegendButton } from "../../../components/map/MapLegendButton";

interface ConstructionProjectMapProps {
  beacons: Beacon[] | undefined;
  constructionSites: ConstructionSite[] | undefined;
  constructionProjects: ConstructionProject[] | undefined;
  isLoading: boolean;
}

export function ConstructionProjectMap({
  beacons,
  constructionSites,
  constructionProjects,
  isLoading,
}: ConstructionProjectMapProps) {
  const { nodeVisitiblyDisabledList, setSelectedNode } =
    useNodeSelectedContext();

  const markers = transformBeaconsToMarkers(beacons, setSelectedNode);

  const filteredMarkers = useMemo(
    () =>
      markers.filter(
        (marker) => !nodeVisitiblyDisabledList.includes(marker.title)
      ),
    [markers, nodeVisitiblyDisabledList]
  );

  const filteredConstructionSites =
    constructionSites?.filter(
      (site) => site && !nodeVisitiblyDisabledList.includes(site.id)
    ) || [];

  const filteredConstructionProjects =
    constructionProjects?.filter(
      (project) => project && !nodeVisitiblyDisabledList.includes(project.id)
    ) || [];

  const combinedPolygons = [
    ...getPolygonsProjects(
      filteredConstructionProjects,
      "#4C86F7",
      setSelectedNode
    ),
    ...getPolygonsSites(filteredConstructionSites, "yellow", setSelectedNode),
  ];
  const polylines = getPolylinesSites(
    beacons,
    filteredConstructionSites,
    "yellow",
    3,
    setSelectedNode
  );

  return (
    <>
      <MapLegendButton />
      <Map
        markers={isLoading ? [] : filteredMarkers}
        polylines={isLoading ? [] : polylines}
        polygons={isLoading ? [] : combinedPolygons}
        testId="construction-project-map"
        onMapClicked={() => setSelectedNode({ selectedId: "" })}
      />
    </>
  );
}
