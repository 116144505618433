
import React, { useState, useEffect, useCallback } from 'react';
import { AmplifyLoadingSpinner } from '@aws-amplify/ui-react';
import Amplify, { Auth } from 'aws-amplify';
import './Environment.css';
import { EnvironmentConfig, EnvironmentContext } from '../contexts/environmentContext';


export function Environment(props: { children: React.ReactNode }) {
    const [environmentConfig, setEnvironmentConfig] = useState<EnvironmentConfig | 'NotLoaded' | 'Error'>('NotLoaded');
    const onEnvironmentConfigLoaded = (config: EnvironmentConfig) => {
        const authConfig = {
            mandatorySignIn: true,
            region: config.cognito.region,
            userPoolId: config.cognito.userPoolId,
            userPoolWebClientId: config.cognito.userPoolWebClientId
        };
        Amplify.configure({ Auth: authConfig });
        Auth.configure(authConfig);

        setEnvironmentConfig(config);
    }

    const loadEnvironmentConfig = useCallback(async () => {
        try {
            const configBaseUrl = process.env.NODE_ENV === 'development'
                ? 'https://artemis-ui-config-dev.s3.eu-central-1.amazonaws.com'
                : window.location.host === 'app.cm-one.de'
                    ? 'https://artemis-ui-config-prod.s3.eu-central-1.amazonaws.com'
                    : window.location.origin
                        .replace('artemis-ui', 'artemis-ui-config')
                        .replace('http://', 'https://')
                        .replace('.s3-website.', '.s3.');

            const response = await fetch(configBaseUrl + '/environment-config.json');
            if (response.status !== 200) {
                setEnvironmentConfig('Error');
            }
            else {
                const json = await response.json() as EnvironmentConfig;
                if (process.env.REACT_APP_LOCAL_API === 'true') {
                    json.api.baseUrl = 'http://localhost:5000/';
                    json.api.baseUrlv2 = 'http://localhost:5000/';
                }
                onEnvironmentConfigLoaded(json);
            }
        }
        catch (e) {
            console.error(e);
            setEnvironmentConfig('Error');
        }
    }, []);
    useEffect(() => { loadEnvironmentConfig(); }, [loadEnvironmentConfig]);

    if (environmentConfig === 'NotLoaded') {
        return <div className="environment-centered">
            <AmplifyLoadingSpinner />
            <div>
                Loading...
            </div>
        </div>;
    }

    if (environmentConfig === 'Error') {
        return <div className="environment-centered">
            Error loading environment
        </div>;
    }

    return <EnvironmentContext.Provider value={environmentConfig}>{props.children}</EnvironmentContext.Provider>;
}