import { Alert, Spin } from "antd";
import { useTranslation } from "react-i18next";
import {
  Beacon,
  BeaconPosition,
  BeaconWithPosition,
} from "../../../api";
import { Map, PolyLineData } from "../../map/Map";
import { getMarkerColor } from "../../map/mapUtils";
import { Maximizable } from "../../Maximizable";

interface BeaconDetailMapProps {
  loading: boolean;
  lastLoadedAt?: Date;
  beacon: Beacon;
  position?: BeaconPosition;
  polyLines?: PolyLineData[];
}

export function BeaconMapDetails({
  loading,
  beacon,
  position,
  polyLines,
}: BeaconDetailMapProps) {
  const { t } = useTranslation();

  if (loading && !position) {
    return <Spin className="centered" aria-label="loading-spinner" />;
  }

  if (!position) {
    return (
      <>
        {loading && <Spin className="centered" />}
        <Alert
          style={{
            position: "relative",
            top: "10px",
          }}
          message={t("no-position")}
          type="info"
          showIcon
        />
      </>
    );
  }

  return (
    <Maximizable>
      <Map
        markers={[
          {
            lat: position.value.pos.lat,
            lon: position.value.pos.lon,
            color: getMarkerColor(beacon as BeaconWithPosition),
            title: t("beacon:marker-title", {
              serial: beacon.serial || beacon.id,
              at: position.measuredAt.toLocaleString(),
            }),
            onClick: () => {},
          },
        ]}
        polylines={polyLines}
      />
    </Maximizable>
  );
}
