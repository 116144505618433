import { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Beacon, BeaconTableColumns } from "../../../../api";
import { PagedApiState } from "../../../../api/useApiState";
import { AssignBeaconsModalConstructionSite } from "../../../../components/beacon-configuration/AssignBeaconsModal";
import { BulkConfigureModal } from "../../../../components/beacon-configuration/BulkConfigureModal";
import { UnassignBeaconsButtonSite } from "../../../../components/beacon-configuration/UnassignBeaconsButton";
import { BeaconTableBase } from "../../../../components/BeaconTableBase";
import { AuthContext } from "../../../../contexts/authContext";

interface BeaconDetailsProps {
  constructionProjectId: string;
  constructionSiteId: string;
  siteBeacons: PagedApiState<Beacon>;
  projectBeacons: PagedApiState<Beacon>;
}

const useSelectedBeacons = () => {
  const [selectedBeaconIds, setSelectedBeaconIds] = useState<string[]>([]);

  const onSelect = (selectedRowKeys: string[]) => {
    setSelectedBeaconIds(selectedRowKeys);
  };

  const clearSelectedBeaconIds = () => {
    setSelectedBeaconIds([]);
  };

  return { selectedBeaconIds, onSelect, clearSelectedBeaconIds };
};

export const BeaconDetails = ({
  constructionProjectId,
  constructionSiteId,
  siteBeacons,
  projectBeacons,
}: BeaconDetailsProps) => {
  const { t } = useTranslation();
  const authContext = useContext(AuthContext);
  const currentUserIsRSM = authContext.currentUser?.accessToken?.hasRole(
    "road-safety-manager"
  );
  
  const { selectedBeaconIds, onSelect } = useSelectedBeacons();

  return (
    <div>
      <div className="sub-title-container">
        {t("construction-site:beacons-in-site")}
      </div>
      <BeaconTableBase
        beaconData={siteBeacons}
        visibleColumns={[
          BeaconTableColumns.Serial,
          BeaconTableColumns.Status,
          BeaconTableColumns.ConfigurationButton,
        ]}
        showPagination={false}
        rowsSelectable={true}
        onSelect={onSelect}
        showFooter={true}
        showSorter={false}
        footerContent={
          <div className="footer-content">
            {currentUserIsRSM && (
              <>
                <AssignBeaconsModalConstructionSite
                  constructionProjectId={constructionProjectId}
                  constructionSiteId={constructionSiteId}
                  beacons={projectBeacons}
                />
                <UnassignBeaconsButtonSite
                  selectedBeaconIds={selectedBeaconIds}
                  constructionProjectId={constructionProjectId}
                />
              </>
            )}
            {/* 
            Removing this component from the UI until it is implemented fully
            <BulkConfigureModal selectedBeaconIds={selectedBeaconIds} /> 
            */}
          </div>
        }
      />
    </div>
  );
};
