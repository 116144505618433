import { useCallback, useContext } from "react";
import { FromToFilter, useFromToFilter, useRefreshCounter } from "./api.utils";
import { BeaconEnvironment, BeaconPosition, BoardType } from "./models";
import { ApiState, useApiState, useGetAuthorized } from "./useApiState";
import { EnvironmentContext } from "../contexts/environmentContext";

function useHistory(constructionProjectId: string | undefined, 
        beaconId: string, fromToFilter: FromToFilter, type: 'POS' | 'ENV', 
        boardType: BoardType = BoardType.STM_PLATFORM, 
        endPath: string = 'position-telemetry',
        legacyMapper: (nm: TMessage[] | TMessageResult) => TMessage[]): ApiState<TMessage[] | TMessageResult | undefined> {
    const refreshCounter = useRefreshCounter();
    const fixDates = useCallback((positions: TMessage[], apiV: number) => {
        if (apiV === 1 && !Array.isArray(positions)) {
            return [];
        }
        if (apiV === 2){
            positions = legacyMapper(positions);
        }
        positions.forEach(p => {
            p.measuredAt = new Date(p.measuredAt!);
        });

        return positions;
    }, [legacyMapper]);
    const appendDateFilter = useFromToFilter(fromToFilter);
      let manipulateUrl = (url: string) => {
        return appendDateFilter(url)
      }
      const environmentContext = useContext(EnvironmentContext);
      let apiVersion = 1;
      if(boardType === 2){
        apiVersion = 2;
        manipulateUrl = (url: string) => {
            let manipulatedUrl = appendDateFilter(url.replace(environmentContext.api.baseUrl, "https://i5ge0av74k.execute-api.eu-central-1.amazonaws.com/"))
            manipulatedUrl = manipulatedUrl.replace("from=", "start=")
            manipulatedUrl = manipulatedUrl.replace("to=", "end=")
            manipulatedUrl = manipulatedUrl.replace("skip=", "offset=")
            manipulatedUrl = manipulatedUrl.replace("take=", "limit=")
            manipulatedUrl = manipulatedUrl.replace("history?", `history/${endPath}?`)
            manipulatedUrl = manipulatedUrl.replace("messageType=POS&", '')
            manipulatedUrl = manipulatedUrl.replace("messageType=ENV&", '')
            manipulatedUrl = manipulatedUrl.replace("messageType=LIFE&", '')
            manipulatedUrl = manipulatedUrl.replace("messageType=ERR&", '')
            return manipulatedUrl
        }
      }

    const url = constructionProjectId
        ? `construction-projects/${constructionProjectId}/beacons/${beaconId}/history?messageType=${type}&take=1000`
        : `beacons/${beaconId}/history?messageType=${type}&take=10000`;

    const get = useGetAuthorized<TMessage[]>(url, fixDates, useCallback(manipulateUrl, [appendDateFilter]), apiVersion);
    return useApiState(get, undefined, refreshCounter);
}

export function usePositionHistory(constructionProjectId: string | undefined, beaconId: string, fromToFilter: FromToFilter, 
  boardType: BoardType = BoardType.STM_PLATFORM): ApiState<BeaconPosition[] | TMessage[] | TMessageResult | undefined> {
    const mapNordicToLegacy = (nm: TMessageResult | TMessage[]): TMessage[] => {
        const nmResult = nm as TMessageResult
        return nmResult.items.map(p => {
            return {
                measuredAt: p.position!.ts,
                reason: 1,
                value: {
                    pos: {
                        lat: p.position!.lat,
                        lon: p.position!.lon,
                        alt: 0
                    },
                    md: {
                        hAcc: p.position!.acc.h,
                        vAcc: p.position!.acc.v,
                        tAcc: p.position!.acc.t,
                        hDop: p.position!.dop.h,
                        pDop: p.position!.dop.p,
                        vDop: p.position!.dop.v,
                        satU: p.position!.sats,
                        pst: p.position!.f,
                        cprs: 0
                    }
                }
            }
        })
    }
    return useHistory(constructionProjectId, beaconId, fromToFilter, 'POS', boardType, 'position-telemetry', mapNordicToLegacy);
}

export function useEnvironmentHistory(constructionProjectId: string | undefined, beaconId: string, fromToFilter: FromToFilter, 
  boardType: BoardType = BoardType.STM_PLATFORM): ApiState<BeaconEnvironment[] | TMessage[] | TMessageResult | undefined> {
    const mapNordicToLegacy = (nm: TMessageResult | TMessage[]): TMessage[] => {
        const nmResult = nm as TMessageResult
        return nmResult.items.map(s => {
            return {
                measuredAt: s.sensors!.batt.ts,
                reason: 1,
                value: {
                    batt: {
                        v: s.sensors!.batt.v
                    },
                    cell: {
                        iccid: ''
                    },
                    remainingRuntimeHours: 4,
                    remainingRuntimeRatio: 25.0
                }
            }
        })
    }
    return useHistory(constructionProjectId, beaconId, fromToFilter, 'ENV', boardType, 'sensor-telemetry', mapNordicToLegacy);
}

interface TMessageResult {
  items: TMessage[]
}

interface TMessage {
    measuredAt?: Date,
    reason?: number,
    position?: {
      ts: Date;
      lat: number;
      lon: number;
      f: number;
      sats: number;
      acc: {
        h: number;
        v: number;
        t: number;
      },
      dop: {
        g: number;
        p: number;
        h: number;
        v: number;
        t: number;
      }
    };
    value?: {
        pos?: {
          lat: number;
          lon: number;
          alt: number;
        };
        md?: {
          hAcc: number;
          vAcc: number;
          tAcc: number;
          hDop: number;
          pDop: number;
          vDop: number;
          satU: number;
          pst: number;
          cprs: number;
        };
        batt?: {
            v: number;
          };
        cell?: {
            iccid: string;
        };
        remainingRuntimeHours?: number;
        remainingRuntimeRatio?: number;
      };
      sensors?: {
        accel: {
          x: number,
          y: number,
          z: number
        },
        batt: {
          v: number,
          ts: Date,
          hoursLeft: number,
          percentLeft: number
        }
      };
}
