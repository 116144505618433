type CSSWidth =
  | `${number}px`
  | `${number}em`
  | `${number}rem`
  | `${number}%`
  | "auto";

interface TruncatedLongTextProps {
  text: string;
  maxLength: CSSWidth;
  id?: string;
  onClick?: (id: string) => void;
}

export function TruncatedLongText({ text, maxLength, id, onClick }: TruncatedLongTextProps) {
  return (
    <div
      style={{
        maxWidth: `${maxLength}`,
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
      }}
      title={text}
      onClick={id ? () => onClick && onClick(id) : undefined}
    >
      {text}
    </div>
  );
}
