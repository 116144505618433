import { Table, Tag } from "antd";
import { useTranslation } from "react-i18next";
import { BeaconLifecycle, isOff, isOn, isMovement, isHeartbeat } from "../../../api";
import { PagedApiState } from "../../../api/useApiState";
import { toAntdPagination } from "../../../util";

interface BeaconEventTableProps {
  lifecycleHistory: PagedApiState<BeaconLifecycle>;
}

export function BeaconEventTable({ lifecycleHistory }: BeaconEventTableProps) {
  const { t } = useTranslation();

  return (
    <Table
      sticky
      dataSource={lifecycleHistory.value?.items || []}
      rowKey="measuredAt"
      size="middle"
      loading={lifecycleHistory.loading}
      columns={[
        {
          title: t("lifecycle:measured-at"),
          dataIndex: "measuredAt",
          sorter: (a, b) =>
            (a.measuredAt?.getTime() || 0) - (b.measuredAt?.getTime() || 0),
          render: (measuredAt) =>
            measuredAt
              ? measuredAt.toLocaleString()
              : t("beacon:is-on-off-unknown"),
        },
        {
          title: t("lifecycle:status"),
          render: (e: BeaconLifecycle) =>
            isOn(e.value) ? (
              <Tag color="green">{t("beacon:is-on-off-on")}</Tag>
            ) : isOff(e.value) ? (
              <Tag color="black">{t("beacon:is-on-off-off")}</Tag>
            ) : isMovement(e.value) ? (
              <Tag color="violet">{t("beacon:is-on-off-movement")}</Tag>
            ) : isHeartbeat(e.value) ? (
              <Tag color="orange">{t("beacon:is-on-off-heartbeat")}</Tag>
            ) : (
              <Tag color="gray">
                {t("beacon:is-on-off-unknown")}
              </Tag>
            ),
        },
      ]}
      pagination={toAntdPagination(lifecycleHistory, false)}
    ></Table>
  );
}