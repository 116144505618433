import { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  ConstructionProject as ConstructionProjectInterface,
  useBeacons,
  useConstructionProjects,
  useGetAllConstructionProjectSites,
} from "../../api";
import { useRefreshAll } from "../../util";
import "./construction-project.scss";
import { ConstructionProjectMap } from "./map/ConstructionProjectMap";
import { useNodeSelectedContext } from "./NodeSelectedContext";
import { ConstructionProjectTree } from "./project-tree/ConstructionProjectTree";
import { ConstructionProjectDrawer } from "./drawers/project/ConstructionProjectDrawer";
import { ConstructionSiteDrawer } from "./drawers/site/ConstructionSiteDrawer";

interface HistoryState {
  selectedId: string;
}

export function ConstructionProjectWrapper() {
  const [refreshTimeStamp, setRefreshTimeStamp] = useState<string>("");
  const { selectedNode, setSelectedNode } = useNodeSelectedContext();
  const history = useHistory();

  useEffect(() => {
    if (refreshTimeStamp) {
      refreshAll();
    }
  }, [refreshTimeStamp]);

  /*
    When user is navigating to the construction-project page from a different page after 
    they have clicked on a project or site name on that page (/beaons & /beacon-details), 
    get which project or site was clicked on and set the selectedNode state to that project 
    or site.

    This will set the selected node upon load of the construction project page which will 
    collapse all other nodes and turn other node visible off while having the selected 
    node expanded and visible.
  */
  useEffect(() => {
    if (
      history.location.state &&
      typeof history.location.state === "object" &&
      "selectedId" in history.location.state
    ) {
      const state = history.location.state as HistoryState;
      setSelectedNode({ selectedId: state.selectedId });
    }
  }, [history]);

  /*
    Fetching beacons with take of 1000 as there is not an api to support the use case for the 
    updated construction-project page.

    If implemented a different wayt to fetch all the beacons, orgs, sites and projects there 
    would be many api calls to then have to compose that data into the structure we can get with one
    api call from the /beacons endpoint with a take of 1000. 

    This will obviously exclude beacons when the total number of beacons is greater than 1000.
  */

  const beacons = useBeacons(undefined, undefined, undefined, undefined, 1000);
  const projects = useConstructionProjects(undefined, false);
  const projectIds = useMemo(
    () =>
      projects.value?.items.map(
        (project: ConstructionProjectInterface) => project.id
      ) || [],
    [projects.loading]
  );
  const sites = useGetAllConstructionProjectSites(projectIds);

  /* 
    projectsInBeaconArraywithOnBeacons and filteredProjects are used to filter the projects that are
    displayed on the map. This is to address data issues when a project could be returned from the 
    construction-projects API that does not have any beacons associated with it or only off beacons. 

    If a project with no beacons or only off beacons, then the polygon for that project will always be 
    present on the map and cause update bounds/view issues when changing visibility in the tree.
  */
  const projectsInBeaconArrayWithOnBeacons = useMemo(() => {
    if (!beacons.value?.items) {
      return [];
    }
    const projectIds = beacons.value.items
      .filter((beacon) => beacon.onOffSwitch?.isOn !== false)
      .map((beacon) => beacon.constructionProjectId)
      .filter((projectId) => projectId !== null);

    return Array.from(new Set(projectIds));
  }, [beacons.value?.items]);

  const filteredProjects = useMemo(() => {
    if (!projects.value?.items) {
      return [];
    }
    return projects.value?.items.filter((project) =>
      projectsInBeaconArrayWithOnBeacons.includes(project.id)
    );
  }, [projects.value?.items, projectsInBeaconArrayWithOnBeacons]);

  const refreshAll = useRefreshAll(beacons);

  const dataLoading = beacons.loading || projects.loading || sites.loading;

  return (
    <div className="project-container">
      <div className="column-one">
        <ConstructionProjectTree
          beacons={beacons.value?.items}
          isLoading={dataLoading}
          defaultIdExpanded={selectedNode.selectedId ?? ""}
        />
      </div>
      <div className="column-two">
        <ConstructionProjectMap
          beacons={beacons.value?.items}
          constructionSites={sites.value}
          constructionProjects={filteredProjects}
          isLoading={dataLoading}
        />
      </div>
      <ConstructionProjectDrawer
        constructionProjectId={selectedNode.selectedProjectId ?? ""}
        constructionProjectName={selectedNode.selectedProjectName ?? ""}
        drawerVisible={selectedNode.projectDrawerVisible ?? false}
        onSave={(refreshTimeStamp: string) =>
          setRefreshTimeStamp(refreshTimeStamp)
        }
      />
      {selectedNode.siteDrawerVisible ? (
        <ConstructionSiteDrawer
          constructionProjectId={selectedNode.selectedProjectId ?? ""}
          beacons={beacons}
          constructionProjectName={selectedNode.selectedProjectName ?? ""}
          constructionSiteId={selectedNode.selectedSiteId ?? ""}
          drawerVisible={selectedNode.siteDrawerVisible ?? false}
          onSave={(refreshTimeStamp: string) =>
            setRefreshTimeStamp(refreshTimeStamp)
          }
        />
      ) : null}
    </div>
  );
}
