import { Alert, Spin } from "antd";
import { useTranslation } from "react-i18next";
import { EditableText } from "../../../../util/EditableText";
import { NavigatableText } from "../../../../util/NavigatableText";
import { UpdateApiResponse } from "../useUpdateInformation";
import { useContext } from "react";
import { AuthContext } from "../../../../contexts/authContext";

interface SummaryInformationProps {
  dateCreated: Date;
  location: string;
  organizationName: string;
  organizationId: string;
  isLoading: boolean;
  updateResult: UpdateApiResponse;
  onSave: (city: string) => Promise<void>;
}

export const SummaryInformation = ({
  dateCreated,
  location,
  organizationName,
  organizationId,
  isLoading,
  updateResult,
  onSave,
}: SummaryInformationProps) => {
  const { t } = useTranslation();

  const authContext = useContext(AuthContext);
  const currentUserIsRSM = authContext.currentUser?.accessToken?.hasRole(
    "road-safety-manager"
  );

  return (
    <div>
      {updateResult.status === 400 &&
        updateResult.updatedValue.key === "projectLocation" && (
          <Alert
            message={t("translations:update-location-failed")}
            type="error"
            closable
          />
        )}
      {updateResult.status === 200 &&
        updateResult.updatedValue.key === "projectLocation" && (
          <Alert
            message={t("translations:update-location-success")}
            type="success"
            closable
          />
        )}
      {isLoading ? (
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Spin size="default" />
        </div>
      ) : (
        <table className="summary-table">
          <tbody>
            <tr>
              <td className={"align-right"}>
                {t("translations:created-date")}:&nbsp;&nbsp;
              </td>
              <td>
                {/* {dateCreated.toLocaleString()} */}
                Not available
              </td>
            </tr>
            <tr>
              <td className={"align-right"}>
                {t("translations:location")}:&nbsp;&nbsp;
              </td>
              <td>
                {currentUserIsRSM ? (
                  <EditableText
                    text={location}
                    style={{ height: "20px", width: "300px" }}
                    saveCallback={(value) => onSave(value)}
                  />
                ) : (
                  location
                )}
              </td>
            </tr>
            <tr>
              <td className={"align-right"}>
                {t("translations:organization")}:&nbsp;&nbsp;
              </td>
              <td>
                <NavigatableText
                  text={organizationName}
                  type="router"
                  route={`/organizations/${organizationId}`}
                />
              </td>
            </tr>
          </tbody>
        </table>
      )}
    </div>
  );
};
