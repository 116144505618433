import React from 'react';

export interface EnvironmentConfig {
    api: {
        baseUrl: string;
        baseUrlv2?: string
    };
    cognito: {
        region: string;
        userPoolId: string;
        userPoolWebClientId: string;
    };
}

export const EnvironmentContext = React.createContext<EnvironmentConfig>(undefined!);