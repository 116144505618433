import {
  Beacon,
  ConstructionProject,
  ConstructionSite,
  BeaconWithPosition,
  MarkerColor,
} from "../../api";
import { MarkerData, PolyLineData, PolygonData } from "./Map";

export const getPolygonsSites = <T>(
  sites: ConstructionSite[],
  color: string,
  onClickEvent: (node: T) => void
): PolygonData[] => {
  try {
    return sites
      .filter((coord) => coord.polygon !== null)
      .map((coord) => ({
        color: color,
        ring: coord.polygon!,
        title: coord.name,
        id: coord.id,
        onClick: () => onClickEvent({ selectedId: coord.id } as unknown as T),
      }));
  } catch (error) {
    console.log("Error in getPolygonsSites", error);
    return [];
  }
};

export const getPolygonsProjects = <T>(
  projects: ConstructionProject[],
  color: string,
  onClickEvent: (node: T) => void
): PolygonData[] => {
  try {
    return projects
      .filter((coord) => coord.area !== null)
      .map((coord) => ({
        color: color,
        ring: coord.area!,
        title: coord.name,
        id: coord.id,
        onClick: () => onClickEvent({ selectedId: coord.id } as unknown as T),
      }));
  } catch (error) {
    console.log("Error in getPolygonsProjects", error);
    return [];
  }
};

// No API as of Feb 25 to get this data, so handling it in client side
export const getPolylinesSites = <T>(
  beacons: Beacon[] | undefined,
  sites: ConstructionSite[] | undefined,
  color: string,
  width: number,
  onClickEvent: (node: T) => void
): PolyLineData[] => {
  if (!sites || !beacons) return [];

  try {
    return sites
      .map((site) => {
        const beaconsForSite =
          beacons.filter((beacon) => beacon.constructionSiteId === site.id) ??
          [];
        const positions = beaconsForSite
          .filter(
            (beacon): beacon is BeaconWithPosition =>
              (beacon as BeaconWithPosition).position !== null
          )
          .map((beacon) => beacon.position.value.pos);
        const count = positions.length;
        return { siteId: site.id, siteName: site.name, positions, count };
      })
      .filter((siteBeaconCount) => siteBeaconCount.count === 2)
      .map((site) => ({
        line: site.positions,
        title: site.siteName,
        id: site.siteId,
        color: color,
        width: width,
        onClick: () =>
          onClickEvent({ selectedId: site.siteId } as unknown as T),
      }));
  } catch (error) {
    console.log("Error in getPolylinesSites", error);
    return [];
  }
};

export const transformBeaconsToMarkers = <T>(
  beacons: Beacon[] | undefined,
  onClickEvent: (node: T) => void
): MarkerData[] => {
  if (beacons === undefined) return [];

  try {
    const filteredBeacons = beacons.filter(
      (beacon: Beacon) => beacon.onOffSwitch?.isOn === true
    );

    const markers: MarkerData[] = (filteredBeacons as BeaconWithPosition[])
      .map((beacon) => {
        if (beacon.position === null) return undefined;
        return {
          title: beacon.serial,
          lat: beacon.position.value.pos.lat,
          lon: beacon.position.value.pos.lon,
          color: getMarkerColor(beacon),
          onClick: () =>
            onClickEvent({ selectedId: beacon.serial } as unknown as T),
        };
      })
      .filter((marker) => marker !== undefined) as MarkerData[];

    return markers;
  } catch (error) {
    console.log("Error in transformBeaconsToMarkers", error);
    return [];
  }
};

export const getMarkerColor = (beacon: BeaconWithPosition): MarkerColor => {
  if (beacon.onOffSwitch?.isOn === false) {
    return "grey";
  }

  if (beacon.activeErrors?.length) {
    return "red";
  }

  return beacon.position?.value.md.cprs === 2 ? "green" : "orange";
};
