import { useTranslation } from "react-i18next";
import "./Map.css";

export enum LegendItem {
  Markers = "Markers",
  Polygons = "Polygons",
}

interface BeaconMapLegendProps {
  legendItems: LegendItem[];
  style?: React.CSSProperties;
}

export function BeaconMapLegend({ style, legendItems }: BeaconMapLegendProps) {
  const { t } = useTranslation();

  const legendItemsMarkers = [
    { color: "green", text: t("beacon:on-accurate-position") },
    { color: "orange", text: t("beacon:on-inaccurate-position") },
    { color: "red", text: t("beacon:on-error") },
    { color: "grey", text: t("beacon:off") },
  ];

  const legendItemsPolygons = [
    { color: "#5A85EF", text: t("translations:construction-project") },
    { color: "yellow", text: t("translations:construction-site") },
  ];

  return (
    <div
      className="beacon-map-info-button-popup"
      style={style ? style : { top: "0px", right: "35px" }}
    >
      {legendItems.includes(LegendItem.Markers) &&
        legendItemsMarkers.map((item, index) => (
          <p key={index}>
            <span style={{ color: item.color }}>●</span> {item.text}
          </p>
        ))}
      {legendItems.includes(LegendItem.Polygons) &&
        legendItemsPolygons.map((item, index) => (
          <p key={index}>
            <span style={{ color: item.color }}>●</span> {item.text}
          </p>
        ))}
    </div>
  );
}
