import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { PlusCircleOutlined } from "@ant-design/icons";
import {
  Beacon,
  useAssignBeaconsToOrganization,
  useAssignConstructionSite,
  useBeacons,
} from "../../api";
import { SelectBeaconsModal } from "./SelectBeaconsModal";
import { DataRefreshContext } from "../../contexts/dataRefreshContext";
import { PagedApiState } from "../../api/useApiState";

interface AssignBeaconsModalConstructionSiteProps {
  constructionProjectId: string;
  constructionSiteId: string;
  beacons: PagedApiState<Beacon>;
  title?: string;
  modalButton?: (openModal: () => void) => React.ReactNode;
}

export function AssignBeaconsModalConstructionSite({
  constructionProjectId,
  constructionSiteId,
  beacons,
  title,
  modalButton,
}: AssignBeaconsModalConstructionSiteProps) {
  const { t } = useTranslation();
  const assignBeaconsToConstructionSite = useAssignConstructionSite(
    constructionProjectId
  );
  const dataRefreshContext = useContext(DataRefreshContext);

  const onAssignBeaconsToConstructionProject = async (beaconIds: string[]) => {
    await assignBeaconsToConstructionSite({
      constructionSiteId: constructionSiteId,
      beaconIds: beaconIds,
    });

    dataRefreshContext.refresh();
  };

  return (
    <SelectBeaconsModal
      title={title ?? t("construction-project:assign-beacons")}
      instruction={t("construction-project:assign-beacons-drawing-tooltip")}
      beacons={beacons}
      selectButtonText={(beaconIds) =>
        t("construction-project:assign-beacons", { count: beaconIds.length })
      }
      selectButtonTitle={(beaconIds) =>
        t("construction-project:assign-beacons-message", {
          count: beaconIds.length,
        })
      }
      modalButton={modalButton}
      modalButtonIcon={modalButton ? undefined : <PlusCircleOutlined />}
      onBeaconsSelected={onAssignBeaconsToConstructionProject}
    />
  );
}

interface AssignBeaconsModalOrganizationProps {
  organizationId: string;
}

export function AssignBeaconsModalOrganization({
  organizationId,
}: AssignBeaconsModalOrganizationProps) {
  const { t } = useTranslation();
  const assignBeaconsToOrganization = useAssignBeaconsToOrganization();
  const dataRefreshContext = useContext(DataRefreshContext);

  const beacons = useBeacons(false, undefined, undefined, undefined);

  const onAssignBeaconsToOrganization = async (beaconIds: string[]) => {
    await assignBeaconsToOrganization({ beaconIds, organizationId });
    dataRefreshContext.refresh();
  };

  return (
    <SelectBeaconsModal
      title={t("organization:assign-beacons")}
      instruction={t("organization:assign-beacons-drawing-tooltip")}
      beacons={beacons}
      assignedToOrganization={false}
      selectButtonText={(beaconIds) =>
        t("organization:assign-beacons", { count: beaconIds.length })
      }
      selectButtonTitle={(beaconIds) =>
        t("organization:assign-beacons-message", { count: beaconIds.length })
      }
      modalButtonIcon={<PlusCircleOutlined />}
      onBeaconsSelected={onAssignBeaconsToOrganization}
    />
  );
}
