import { useCallback, useContext } from "react";
import { DataAutoRefreshContext } from "../contexts/dataRefreshContext";
import { useIncrementingCounter } from "../util";

export type FromToFilter = number | { from: Date, to: Date };

export function useFromToFilter(filter: FromToFilter, apiVersion: number = 1) {

    return useCallback((url: string) => {
        let fromText = 'from';
        let toText = 'to';
        if (apiVersion === 2) {
            fromText = 'start';
            toText = 'end';
        }
        if (typeof (filter) === 'number') {
            const to = new Date();
            const from = new Date(to.getTime() - filter * 1000);
            return url + `&${fromText}=` + from.toISOString() + `&${toText}=` + to.toISOString();
        }
        return url + `&${fromText}=` + filter.from.toISOString() + `&${toText}=` + filter.to.toISOString();
    }, [filter, apiVersion]);
}

export function useRefreshCounter() {
    const { interval, disabled } = useContext(DataAutoRefreshContext);
    return useIncrementingCounter(interval, disabled);
}