import { PlusOutlined } from "@ant-design/icons";
import { Button, Tabs } from "antd";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Beacon, FromToFilter, useLifecycleHistory } from "../../../api";
import { BeaconEventDrawer } from "./BeaconEventDrawer";
import { BeaconEventTable } from "./BeaconEventTable";

interface BeaconEventCardProps {
  beacon: Beacon;
  selectedFromToFilter: FromToFilter & { from?: Date; to?: Date };
}

export function BeaconEventCard({
  beacon,
  selectedFromToFilter,
}: BeaconEventCardProps) {
  const [eventDrawerVisible, setEventDrawerVisible] = useState(false);
  const { t } = useTranslation();
  let idOrSerial = beacon.serial
  if (beacon.boardType === 1){
    idOrSerial = beacon.id
  }
  const lifecycleHistory = useLifecycleHistory(idOrSerial, selectedFromToFilter, beacon.boardType);

  const handleDrawerOpenClose = (state: boolean) => {
    setEventDrawerVisible(state);
  };

  return (
    <div className="card">
      <div className="card-header">{t("beacon:events")}</div>
      <Tabs
        style={{ height: "100%", marginBottom: "40px" }}
        className="fullsize-tabs"
      >
        <Tabs.TabPane
          key="life"
          style={{ height: "100%", position: "absolute" }}
        >
          <BeaconEventTable lifecycleHistory={lifecycleHistory} />
        </Tabs.TabPane>
      </Tabs>
      <BeaconEventDrawer
        lifecycleHistory={lifecycleHistory}
        eventDrawerVisible={eventDrawerVisible}
        onCloseDrawer={handleDrawerOpenClose}
      />
      <Button
        aria-label="show-drawer-button"
        className="show-card-drawer-button"
        type="primary"
        onClick={() => handleDrawerOpenClose(true)}
        icon={<PlusOutlined />}
      />
    </div>
  );
}
