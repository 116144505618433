import { SearchOutlined } from "@ant-design/icons";
import { Input, Spin } from "antd";
import Tree from "antd/lib/tree";
import React, { useContext, useEffect, useMemo, useState, memo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { Beacon } from "../../../api";
import { BeaconStatus } from "../../../components/BeaconStatus";
import { AuthContext } from "../../../contexts/authContext";
import { useNodeSelectedContext } from "../NodeSelectedContext";
import {
  TreeNode,
  filteredNodes,
  findNode,
  getAllKeys,
  getChildKeys,
  getParentKeys,
  transformToTreeData,
} from "../project-tree/treeUtils";
import "./../../../styles/typography/icons.scss";
import { TruncatedLongText } from "./../../../util/TruncateLongText";
import {
  BeaconIcon,
  ExpandIcon,
  EyeIcon,
  EyeIconAll,
  InfoIcon,
  OrganizationIcon,
  ProjectIcon,
  SiteIcon,
  EmptyIcon,
} from "./ConstructionProjectIcons";
import "./tree.scss";

interface ConstructionProjectTreeProps {
  beacons: Beacon[] | undefined;
  isLoading: boolean;
  defaultIdExpanded: string;
}

const { DirectoryTree } = Tree;

const RenderTitle = ({
  node,
  defaultIdExpanded = "",
  nodeVisitiblyDisabledList = [],
  handleIconClick = () => {},
  handleTitleClick = () => {},
}: RenderTitleProps) => {
  const { t } = useTranslation();
  const history = useHistory();

  const isOrganization = node.id === "organizationName";
  const isConstructionProject = node.id === "constructionProjectName";
  const isConstructionSite = node.id === "constructionSiteName";
  const isSerail = node.id === "serial";

  const renderSerial = (node: TreeNode) => {
    return (
      <div
        onClick={() => history.push(`/beacons/${node.title.data.serial}`)}
        className="title-line-serial"
      >
        <span
          style={{
            marginRight: "1em",
            color: defaultIdExpanded === node.key ? "#448EF7" : "inherit",
          }}
        >
          {node.title.title}
        </span>
        <BeaconStatus beacon={node.title.data} hideErrorStatus={true} />
      </div>
    );
  };

  return (
    <div onClick={(e) => e.stopPropagation()} className="title-container">
      {isOrganization ? (
        <span className="title-line">
          <OrganizationIcon />
          <TruncatedLongText
            text={node.title.title}
            maxLength="27em"
            id={
              node.title.data.organizationId
                ? node.title.data.organizationId
                : node.key.toString()
            }
            onClick={handleTitleClick}
          />
        </span>
      ) : isConstructionProject ? (
        <span className="title-line title-text-bold">
          <ProjectIcon />
          <TruncatedLongText
            text={node.title.title}
            maxLength="23em"
            id={
              node.title.data.constructionProjectId
                ? node.title.data.constructionProjectId
                : node.key.toString()
            }
            onClick={handleTitleClick}
          />
        </span>
      ) : isConstructionSite ? (
        <span className="title-line">
          <SiteIcon />
          <TruncatedLongText
            text={node.title.title}
            maxLength="21em"
            id={
              node.title.data.constructionSiteId
                ? node.title.data.constructionSiteId
                : node.key.toString()
            }
            onClick={handleTitleClick}
          />
        </span>
      ) : (
        <div className="title-line">
          <BeaconIcon />
          {renderSerial(node)}
        </div>
      )}
      <span style={{ textAlign: "right" }}>
        {/* 
            Need to account for beacons that do not have sites assigned
            If no site is assigned, do not show the info icon
           */}
        {isSerail || isOrganization ? null : node.title.title ===
          "Unassigned" ? null : (
          <InfoIcon data={node} id="info" handleIconClick={handleIconClick} />
        )}
        {node.displayVisibility ? (
          <EyeIcon
            data={node}
            id="visible-single"
            disabled={nodeVisitiblyDisabledList.includes(node.key.toString())}
            handleIconClick={handleIconClick}
          />
        ) : (
          <EmptyIcon />
        )}
      </span>
    </div>
  );
};

const MemoizedRenderTitle = memo(RenderTitle);

export function ConstructionProjectTree({
  beacons,
  isLoading,
  defaultIdExpanded,
}: ConstructionProjectTreeProps) {
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [expandedKeys, setExpandedKeys] = useState<React.Key[]>([]);
  const [savedExpandedKeys, setSavedExpandedKeys] = useState<React.Key[]>([]);

  const authContext = useContext(AuthContext);
  const isSolutionOperator =
    authContext.currentUser?.accessToken?.hasRole("solution-operator") ?? false;

  const {
    nodeVisitiblyDisabledList,
    setSelectedNode,
    setNodeVisitiblyDisabledList,
  } = useNodeSelectedContext();

  const treeData = useMemo(() => {
    return transformToTreeData(beacons, isSolutionOperator);
  }, [beacons, isSolutionOperator]);

  const [filteredNodeData, filteredNodeKeys] = useMemo(() => {
    if (!treeData) return [[], []];
    else return filteredNodes(treeData, searchTerm);
  }, [treeData, searchTerm]);

  /*
    When user clicks on a marker or polygon, expand that node on load
  */
  useEffect(() => {
    if (defaultIdExpanded && treeData) {
      const targetNode = findNode(treeData, defaultIdExpanded);
      const expandedKeys = getChildKeys(targetNode);
      const parentKeys = getParentKeys(defaultIdExpanded, treeData);
      const allExpandedKeys = [...expandedKeys, ...parentKeys];
      setExpandedKeys(allExpandedKeys);
    }
  }, [defaultIdExpanded]);

  /*
    When coming from a user clicking on a project from a different page (beacons, beacon details), expand that node on load
    make other nodes not visible
  */
  useEffect(() => {
    if (defaultIdExpanded && treeData) {
      const targetNode = findNode(treeData, defaultIdExpanded);
      const expandedKeys = getChildKeys(targetNode);
      const parentKeys = getParentKeys(defaultIdExpanded, treeData);

      if (parentKeys) {
        const allExpandedKeys = [...expandedKeys, ...parentKeys];
        setExpandedKeys(allExpandedKeys);

        const filteredExpandedKeys = getAllKeys(treeData).filter(
          (key) => !allExpandedKeys.includes(key)
        );

        setNodeVisitiblyDisabledList(filteredExpandedKeys);
      } else {
        setExpandedKeys(expandedKeys);

        const filteredExpandedKeys = getAllKeys(treeData).filter(
          (key) => !expandedKeys.includes(key)
        );

        setNodeVisitiblyDisabledList(filteredExpandedKeys);
      }
    }
  }, [treeData]);

  useMemo(() => {
    if (searchTerm !== "") {
      setExpandedKeys(filteredNodeKeys);
    } else {
      setExpandedKeys(savedExpandedKeys);
    }
  }, [searchTerm]);

  useMemo(() => {
    if (searchTerm === "") {
      setSavedExpandedKeys(expandedKeys);
    }
  }, [expandedKeys]);

  const handleTitleClick = (id: string) => {
    if (id && treeData) {
      const targetNode = findNode(treeData, id);
      const expandedKeys = getChildKeys(targetNode);
      const parentKeys = getParentKeys(id, treeData);
      const allExpandedKeys = [...expandedKeys, ...parentKeys];
      const filteredExpandedKeys = getAllKeys(treeData).filter(
        (key) => !allExpandedKeys.includes(key)
      );
      setNodeVisitiblyDisabledList(filteredExpandedKeys);
      setExpandedKeys(allExpandedKeys);
    }
  };

  const handleIconClick = (node: TreeNode | undefined, action: string) => {
    switch (action) {
      case "info":
        if (node?.id === "constructionSiteName") {
          setSelectedNode({
            siteDrawerVisible: true,
            selectedSiteId: node?.title.data.constructionSiteId,
            selectedProjectId: node?.title?.data.constructionProjectId,
            selectedProjectName: node?.title?.data.constructionProjectName,
          });
        } else if (node?.id === "constructionProjectName") {
          setSelectedNode({
            projectDrawerVisible: true,
            siteDrawerVisible: false,
            selectedProjectId: node?.title?.data.constructionProjectId,
          });
        } else {
          setSelectedNode({
            siteDrawerVisible: false,
            projectDrawerVisible: false,
          });
        }
        break;
      case "visible-single":
        setNodeVisitiblyDisabledList(
          nodeVisitiblyDisabledList.filter(
            (key) => !getChildKeys(node).includes(key)
          )
        );
        break;
      case "visible-single-disabled":
        setNodeVisitiblyDisabledList([
          ...nodeVisitiblyDisabledList,
          ...(getChildKeys(node) ?? ""),
        ]);
        break;
      case "visible-all":
        setNodeVisitiblyDisabledList([]);
        break;
      case "visible-all-disabled":
        setNodeVisitiblyDisabledList(getAllKeys(treeData));
        break;
      case "expand-all":
        setExpandedKeys(getAllKeys(treeData));
        break;
      case "expand-all-disabled":
        setExpandedKeys([]);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <div style={{ height: "75px" }}>
        <SearchBar searchTerm={searchTerm} setSearchTerm={setSearchTerm} />
        <TreeGlobalOptions
          isLoading={isLoading}
          handleIconClick={handleIconClick}
        />
      </div>
      <div data-testid="construction-project-tree" className="tree-scrollable">
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <DirectoryTree
            className="custom-tree"
            showLine={{ showLeafIcon: false }}
            showIcon={true}
            selectable={false}
            titleRender={(node) => (
              <MemoizedRenderTitle
                node={node as TreeNode}
                defaultIdExpanded={defaultIdExpanded}
                nodeVisitiblyDisabledList={nodeVisitiblyDisabledList}
                handleIconClick={handleIconClick}
                handleTitleClick={handleTitleClick}
              />
            )}
            expandedKeys={expandedKeys}
            onExpand={(keys) => setExpandedKeys(keys)}
            treeData={treeData && treeData.length > 0 ? filteredNodeData : []}
            style={{ padding: ".25em" }}
          />
        )}
      </div>
    </>
  );
}

interface SearchBarProps {
  searchTerm: string;
  setSearchTerm: (term: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ searchTerm, setSearchTerm }) => {
  const { t } = useTranslation();

  return (
    <div style={{ margin: ".5em" }}>
      <Input
        id="beacon-table-filter"
        aria-label="beacon-table-filter"
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        suffix={<SearchOutlined />}
        placeholder={t("construction-project:search-placeholder")}
        allowClear
      />
    </div>
  );
};

interface TreeGlobalOptionsProps {
  isLoading: boolean;
  handleIconClick: (node: TreeNode | undefined, action: string) => void;
}

const TreeGlobalOptions = ({
  isLoading,
  handleIconClick,
}: TreeGlobalOptionsProps) => {
  return (
    <div className="header-container" data-testid="global-options">
      <span className="custom-tree">
        <ExpandIcon
          data={undefined}
          id="expand-all"
          disabled={isLoading}
          handleIconClick={handleIconClick}
        />
      </span>
      <span className="custom-tree">
        <EyeIconAll
          id="visible-all"
          data={undefined}
          disabled={isLoading}
          handleIconClick={handleIconClick}
        />
      </span>
    </div>
  );
};

const LoadingSpinner = () => {
  return (
    <div
      data-testid="loading-spinner"
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "5em",
      }}
    >
      <Spin size="default" />
    </div>
  );
};

interface RenderTitleProps {
  node: TreeNode;
  defaultIdExpanded?: string;
  nodeVisitiblyDisabledList?: string[];
  handleIconClick?: (node: TreeNode | undefined, action: string) => void;
  handleTitleClick?: (id: string) => void;
}
